import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { DropdownContext } from "./DropdownContext";
import { cacheBuster } from "./config";

function NewsChannels() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dropdownContext = useContext(DropdownContext);

  if (!dropdownContext) {
    throw new Error("DropdownContext must be used within DropdownProvider");
  }

  const { selectedOption } = dropdownContext;

  // Add new logo name variable here at the end after "primenews", For eg: "newlogoname",
  const [tabs] = useState([
    "tv9telugu",
    "ntvtelugu",
    "abntelugu",
    "sakshitv",
    "v6news",
    "tv10telugu",
    "tnews",
    "etvandhrapradeshlive",
    "tv5telugu",
    "rajnewstelugu",
    "hmtvnews",
    "zeetelugunews",
    "bharattoday",
    "primenews",
  ]);

  const [selectedTeluguTab, setSelectedTeluguTab] = useState<string>(
    () => localStorage.getItem("selectedTeluguTab") || "tv9telugu"
  );

  const handleTabClick = (channelKey: string) => {
    setSelectedTeluguTab(channelKey);
    localStorage.setItem("selectedTeluguTab", channelKey);
  };

  useEffect(() => {
    const savedTab = localStorage.getItem("selectedTeluguTab");
    if (savedTab) {
      setSelectedTeluguTab(savedTab);
    }
  }, []);

  const [visibleTabs, setVisibleTabs] = useState(tabs);
  // Add channel name in "" which you want to hide with comma separated. For eg: const hiddenTabs: any = ["aajtak", "abpnews"];
  const hiddenTabs: any = [""];

  useEffect(() => {
    const updatedVisibleTabs = visibleTabs.filter(
      (tab) => !hiddenTabs.includes(tab)
    );
    setVisibleTabs(updatedVisibleTabs);

    if (hiddenTabs.includes(selectedTeluguTab)) {
      const nextVisibleTab = updatedVisibleTabs[0];
      if (nextVisibleTab) {
        setSelectedTeluguTab(nextVisibleTab);
      }
    }
  }, []);

  /*****  To add new logo add this line with new logo name start ******/
  const tv9telugu = `/assets/channel-logos/tv9-telugu.png?v=${cacheBuster}`;
  /*****  To add new logo add this line with new logo name end ******/
  const ntvtelugu = `/assets/channel-logos/ntv-telugu.jpeg?v=${cacheBuster}`;
  const abntelugu = `/assets/channel-logos/abn-telugu.jpeg?v=${cacheBuster}`;
  const sakshitv = `/assets/channel-logos/sakshi-tv.jpg?v=${cacheBuster}`;
  const v6news = `/assets/channel-logos/v6-news.jpeg?v=${cacheBuster}`;
  const tv10telugu = `/assets/channel-logos/tv10-telugu.jpeg?v=${cacheBuster}`;
  const tnews = `/assets/channel-logos/tnews.jpg?v=${cacheBuster}`;
  const etvandhrapradeshlive = `/assets/channel-logos/etv-andhra-pradesh-live.jpeg?v=${cacheBuster}`;
  const tv5telugu = `/assets/channel-logos/tv5-telugu.jpeg?v=${cacheBuster}`;
  const rajnewstelugu = `/assets/channel-logos/raj-news-telugu.jpg?v=${cacheBuster}`;
  const hmtvnews = `/assets/channel-logos/hmtv-news.jpeg?v=${cacheBuster}`;
  const zeetelugunews = `/assets/channel-logos/zee-telugu-news.jpeg?v=${cacheBuster}`;
  const bharattoday = `/assets/channel-logos/bharat-today.png?v=${cacheBuster}`;
  const primenews = `/assets/channel-logos/prime-news.jpg?v=${cacheBuster}`;

  const imagesToPreload = [
    tv9telugu,
    ntvtelugu,
    abntelugu,
    sakshitv,
    v6news,
    tv10telugu,
    tnews,
    etvandhrapradeshlive,
    tv5telugu,
    rajnewstelugu,
    hmtvnews,
    zeetelugunews,
    bharattoday,
    primenews,
  ];

  imagesToPreload.forEach((src) => {
    const img = new Image();
    img.src = src;
  });

  return (
    <>
      <div className="Apps">
        <Helmet>
          <title>Telugu News Live - Watch Telugu News Channels Online</title>
          <meta
            name="description"
            content="Stay updated with news from Telugu regions. Watch live streams of popular channels like TV9 Telugu, NTV, ABN Telugu, and more on Telugu News."
          />
          <meta
            name="keywords"
            content="Telugu news, live news, TV9 Telugu live, NTV Telugu live, ABN Telugu live, Sakshi TV live, V6 News live, 10TV live, T News live, ETV Andhra Pradesh live, TV5 Telugu live, Raj News Telugu live, HMTV live, ZEE Telugu live, Telugu news channels"
          />
        </Helmet>
        <Header />

        {selectedOption === "Telugu" && (
          <>
            {/* Tabs for channel logos */}
            <div className="logo-grid">
              <div className="tabs">
                {/******  To add new logo copy paste this div(from line number 134 to 153) and change channel name wherever necessary and change the order start *******/}
                {visibleTabs.includes("tv9telugu") && (
                  <div
                    className={`tab-item ${
                      selectedTeluguTab === "tv9telugu" ? "active" : ""
                    } order-1`}
                    onClick={() => handleTabClick("tv9telugu")}
                  >
                    {/**** When you add new logo change the src={tv9telugu} to src={newlogoname} in below div start ****/}
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={tv9telugu}
                        alt="TV9 Telugu Live"
                      />
                    </div>
                    {/**** When you add new logo change the src={tv9telugu} to src={newlogoname} in below div end ****/}
                  </div>
                )}
                {/******  To add new logo copy paste this div and change channel name wherever necessary and change the order end *******/}

                {visibleTabs.includes("ntvtelugu") && (
                  <div
                    className={`tab-item ${
                      selectedTeluguTab === "ntvtelugu" ? "active" : ""
                    } order-2`}
                    onClick={() => handleTabClick("ntvtelugu")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={ntvtelugu}
                        alt="NTV Telugu Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("abntelugu") && (
                  <div
                    className={`tab-item ${
                      selectedTeluguTab === "abntelugu" ? "active" : ""
                    } order-3`}
                    onClick={() => handleTabClick("abntelugu")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={abntelugu}
                        alt="ABN Telugu Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("sakshitv") && (
                  <div
                    className={`tab-item ${
                      selectedTeluguTab === "sakshitv" ? "active" : ""
                    } order-4`}
                    onClick={() => handleTabClick("sakshitv")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={sakshitv}
                        alt="Sakshi TV Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("v6news") && (
                  <div
                    className={`tab-item ${
                      selectedTeluguTab === "v6news" ? "active" : ""
                    } order-5`}
                    onClick={() => handleTabClick("v6news")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={v6news}
                        alt="V6 News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("tv10telugu") && (
                  <div
                    className={`tab-item ${
                      selectedTeluguTab === "tv10telugu" ? "active" : ""
                    } order-6`}
                    onClick={() => handleTabClick("tv10telugu")}
                  >
                    <div className="image-container">
                      <img
                        rel="preload"
                        height="80"
                        width="80"
                        src={tv10telugu}
                        alt="10TV Telugu Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("tnews") && (
                  <div
                    className={`tab-item ${
                      selectedTeluguTab === "tnews" ? "active" : ""
                    } order-7`}
                    onClick={() => handleTabClick("tnews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={tnews}
                        alt="T News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("etvandhrapradeshlive") && (
                  <div
                    className={`tab-item ${
                      selectedTeluguTab === "etvandhrapradeshlive"
                        ? "active"
                        : ""
                    } order-8`}
                    onClick={() => handleTabClick("etvandhrapradeshlive")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={etvandhrapradeshlive}
                        alt="ETV Andhra Pradesh Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("tv5telugu") && (
                  <div
                    className={`tab-item ${
                      selectedTeluguTab === "tv5telugu" ? "active" : ""
                    } order-9`}
                    onClick={() => handleTabClick("tv5telugu")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={tv5telugu}
                        alt="TV5 Telugu News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("rajnewstelugu") && (
                  <div
                    className={`tab-item ${
                      selectedTeluguTab === "rajnewstelugu" ? "active" : ""
                    } order-10`}
                    onClick={() => handleTabClick("rajnewstelugu")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={rajnewstelugu}
                        alt="Raj News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("hmtvnews") && (
                  <div
                    className={`tab-item ${
                      selectedTeluguTab === "hmtvnews" ? "active" : ""
                    } order-11`}
                    onClick={() => handleTabClick("hmtvnews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={hmtvnews}
                        alt="Hmtv News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("zeetelugunews") && (
                  <div
                    className={`tab-item ${
                      selectedTeluguTab === "zeetelugunews" ? "active" : ""
                    } order-12`}
                    onClick={() => handleTabClick("zeetelugunews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={zeetelugunews}
                        alt="ZEE Telugu News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("primenews") && (
                  <div
                    className={`tab-item ${
                      selectedTeluguTab === "primenews" ? "active" : ""
                    } order-13`}
                    onClick={() => handleTabClick("primenews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={primenews}
                        alt="Prime9 News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("bharattoday") && (
                  <div
                    className={`tab-item ${
                      selectedTeluguTab === "bharattoday" ? "active" : ""
                    } order-14`}
                    onClick={() => handleTabClick("bharattoday")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={bharattoday}
                        alt="Bharat Today Live"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Display the selected YouTube video */}

            <div className="video-container">
              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary start  *******/}

              {selectedTeluguTab === "tv9telugu" &&
                visibleTabs.includes("tv9telugu") && (
                  <>
                    <h1>TV9 Telugu Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/II_m28Bm-iM?si=FMymD7IMewL3U_az&autoplay=1&v=${cacheBuster}`}
                      title="TV9 Telugu Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary end *******/}

              {selectedTeluguTab === "ntvtelugu" &&
                visibleTabs.includes("ntvtelugu") && (
                  <>
                    <h1>NTV Telugu Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/L0RktSIM980?si=HVjIViYO1Ov-1qKw&autoplay=1&v=${cacheBuster}`}
                      title="NTV Telugu Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTeluguTab === "abntelugu" &&
                visibleTabs.includes("abntelugu") && (
                  <>
                    <h1>ABN Telugu Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/HoYsWagMFfE?si=GFWpyJEWHbS-0b5R&autoplay=1&v=${cacheBuster}`}
                      title="ABN Telugu Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTeluguTab === "sakshitv" &&
                visibleTabs.includes("sakshitv") && (
                  <>
                    <h1>Sakshi TV Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/9kCcUipLPJM?si=4l0XUWFHrxLpWiu-&autoplay=1&v=${cacheBuster}`}
                      title="Sakshi TV Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTeluguTab === "v6news" &&
                visibleTabs.includes("v6news") && (
                  <>
                    <h1>V6 News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/_xtceKvlVYQ?si=Bm63JtfEASY-FH_1&autoplay=1&v=${cacheBuster}`}
                      title="V6 News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTeluguTab === "tv10telugu" &&
                visibleTabs.includes("tv10telugu") && (
                  <>
                    <h1>10TV Telugu Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/byG7EGw9NPs?si=FAH9p8UyHEBdiVGw&autoplay=1&v=${cacheBuster}`}
                      title="10TV Telugu Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTeluguTab === "tnews" &&
                visibleTabs.includes("tnews") && (
                  <>
                    <h1>T News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/N-4RyrSNDjU?si=WEv07kA5_DTbhXRi&autoplay=1&v=${cacheBuster}`}
                      title="T News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTeluguTab === "etvandhrapradeshlive" &&
                visibleTabs.includes("etvandhrapradeshlive") && (
                  <>
                    <h1>ETV Andhra Pradesh Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/KvZIEgHfscU?si=JMG_DjvFqYhIn_X0&autoplay=1&v=${cacheBuster}`}
                      title="ETV Andhra Pradesh Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTeluguTab === "tv5telugu" &&
                visibleTabs.includes("tv5telugu") && (
                  <>
                    <h1>TV5 Telugu News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/ho9JlBnrGWg?si=Bd_bgflwNL66EEUs&autoplay=1&v=${cacheBuster}`}
                      title="TV5 Telugu News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTeluguTab === "rajnewstelugu" &&
                visibleTabs.includes("rajnewstelugu") && (
                  <>
                    <h1>Raj News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/jdM7gVwBgyw?si=xLMWZqwk9kvuvj9u&autoplay=1&v=${cacheBuster}`}
                      title="Raj News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTeluguTab === "hmtvnews" &&
                visibleTabs.includes("hmtvnews") && (
                  <>
                    <h1>Hmtv News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/3JodH4xoqJs?si=Ky6HTu1R-9PHIkTe&autoplay=1&v=${cacheBuster}`}
                      title="Hmtv News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTeluguTab === "zeetelugunews" &&
                visibleTabs.includes("zeetelugunews") && (
                  <>
                    <h1>ZEE Telugu News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/ffI9-IioJK8?si=hYP6nK0CTHRthM9i&autoplay=1&v=${cacheBuster}`}
                      title="ZEE Telugu News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTeluguTab === "primenews" &&
                visibleTabs.includes("primenews") && (
                  <>
                    <h1>Prime9 News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/4PpS_RGUzfQ?si=dxSOWPMphMYZMatt&autoplay=1&v=${cacheBuster}`}
                      title="Prime9 News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTeluguTab === "bharattoday" &&
                visibleTabs.includes("bharattoday") && (
                  <>
                    <h1>Bharat Today Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/PgzEwgoGfu4?si=TZLN8nSZr5o6mYES&autoplay=1&v=${cacheBuster}`}
                      title="Bharat Today Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}
            </div>
          </>
        )}
      </div>

      <div style={{ display: "none" }}>
        <p>
          Welcome to Telugu News Live – Your Go-To Platform for 24/7 Telugu News{" "}
        </p>
        <p>
          At Telugu News Live, we bring together the most popular Telugu news
          channels in one place, providing you with easy access to real-time
          updates on politics, business, entertainment, sports, and regional
          news. With live streaming links for trusted channels like TV9 Telugu
          Live, NTV Telugu Live, ABN Telugu Live, Sakshi TV Live, and many more,
          our platform ensures that you are always informed and connected to the
          latest happenings in Andhra Pradesh, Telangana, and beyond.
        </p>

        <p>
          All Major Telugu News Channels at Your Fingertips Whether it’s
          breaking news or in-depth coverage of important issues, Telugu News
          Live offers a comprehensive selection of channels, including V6 News
          Live, 10TV Telugu Live, T News Live, and ETV Andhra Pradesh Live. From
          local events to global developments, you’ll find all the news you need
          on one platform. We aim to make your news-watching experience simple
          and hassle-free.
        </p>

        <p>
          Stay Updated with Real-Time News Streaming Our platform provides
          direct access to TV5 Telugu News Live, Raj News Live, and Hmtv News
          Live, ensuring you never miss an update. These channels are known for
          their credible reporting, insightful analysis, and timely delivery of
          news. With Telugu News Live, you can stay ahead of the curve, no
          matter where you are.
        </p>

        <p>
          A Wide Variety of Content for Every Audience From political debates to
          entertainment updates, channels like ZEE Telugu News Live and Prime9
          News Live cater to diverse interests. Whether you are a business
          enthusiast, a sports fan, or someone who loves to stay informed about
          cultural events, Telugu News Live offers a wide range of content
          tailored to your preferences.
        </p>

        <p>
          Local News That Matters to You Our platform understands the importance
          of local news for Telugu-speaking audiences. Channels like Bharat
          Today Live and ETV Andhra Pradesh Live provide in-depth coverage of
          regional stories, ensuring you stay informed about what’s happening in
          your community. From village-level updates to statewide events, Telugu
          News Live keeps you connected to your roots.
        </p>

        <p>
          Breaking News Anytime, Anywhere With live streaming links to channels
          like TV9 Telugu Live and NTV Telugu Live, Telugu News Live ensures you
          are the first to know about breaking stories. Whether it's political
          developments, natural disasters, or significant announcements, you can
          rely on us to bring you the latest updates as they happen.
        </p>

        <p>
          Explore Expert Analysis and Debates For those who love to dig deeper
          into the news, channels like ABN Telugu Live and Sakshi TV Live offer
          detailed analysis, panel discussions, and expert opinions. These
          insights help you understand complex issues, empowering you with
          knowledge and perspective.
        </p>

        <p>
          Your Trusted Source for Telugu News Telugu News Live is designed to
          provide accurate and reliable news from channels that you trust.
          Whether it's live election coverage, community events, or special
          reports, our platform ensures high-quality streaming and uninterrupted
          access to credible news content.
        </p>

        <p>
          Why Choose Telugu News Live? Our mission is to make news accessible to
          Telugu-speaking audiences worldwide. With a user-friendly interface
          and a wide variety of channels, Telugu News Live offers an
          unparalleled news-watching experience. Bookmark our website today and
          enjoy seamless access to all your favorite Telugu news channels in one
          place.
        </p>

        <p>
          Experience the Best of Telugu Journalism At Telugu News Live, we
          celebrate the spirit of Telugu journalism by bringing you a platform
          that combines the best news sources with the convenience of live
          streaming. Stay connected, stay informed, and join thousands of users
          who rely on Telugu News Live for real-time updates and trustworthy
          news.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default NewsChannels;
